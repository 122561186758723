<template>
  <div>
    <v-form @submit.prevent="loadData" id="filter" class="">
      <v-row justify="space-between">
        <v-col cols="12" lg="8">
          <atoms-text-field
            label="Cari Pekerja"
            v-model.trim="term"
            :noLabel="true"
            :appendAction="
              () => {
                term = ''
                loadData()
              }
            "
            appendIcon="mdi-close"
          />
        </v-col>
        <v-col cols="12" lg="2" class="d-flex justify-space-between">
          <atoms-button type="submit" style="width: 100%" class="primary"
            ><v-icon left>mdi-magnify</v-icon>Cari Pekerja</atoms-button
          >
        </v-col>
        <v-col cols="12" lg="2">
          <atoms-button
            class="primary"
            style="width: 100%"
            @click="
              () => {
                sort = sort === constant[0] ? constant[1] : constant[0]
                getRequest()
              }
            "
            ><v-icon left>mdi-filter-variant-{{ sort === constant[0] ? 'minus' : 'plus' }}</v-icon
            >Sort Tgl : {{ sort }}
          </atoms-button>
        </v-col>
        <v-col cols="12" lg="auto">
          <!-- <v-combobox
              class="rounded-lg"
              placeholder="Filters"
              prepend-inner-icon="mdi-filter-variant"
              :items="filters.map((x) => x.title)"
              v-model="filter"
              @change="loadData"
              hide-details
              multiple
              dense
              solo
              flat
              required
              :style="{
                'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
              }"
          /> -->
        </v-col>
      </v-row>
    </v-form>
    <v-row align="center" dense>
      <v-col
        class="shrink"
        v-if="
          checkboxItems &&
          checkboxItems.length > 0 &&
          $store.state.requestPayments.data &&
          $store.state.requestPayments.data.length > 0
        "
      >
        <v-checkbox :disabled="mainLoading" v-model="checkAll" />
      </v-col>
      <v-col v-if="checkboxItems && checkboxItems.length > 0" cols="8" lg="2">
        <atoms-button
          :disabled="mainLoading"
          class="teal white--text"
          style="width: 100%"
          @click="approveBulkRequest(checkboxItems)"
        >
          Approve {{ checkboxItems.length || 0 }} Request
        </atoms-button>
      </v-col>
      <!-- <v-col
        :cols="
          ($vuetify.breakpoint.smAndDown &&
            (!checkboxItems || checkboxItems.length <= 0) &&
            12) ||
          'auto'
        "
      >
        </v-col
      > -->
      <v-col cols="6" lg="2">
        <v-combobox
          class="rounded-lg"
          placeholder="Filters"
          prepend-inner-icon="mdi-filter-variant"
          :items="filters.map((x) => x.title)"
          v-model="filter"
          @change="loadData"
          hide-details
          multiple
          dense
          solo
          flat
          required
          :style="{
            'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
          }"
        />
      </v-col>
      <v-col cols="6" lg="2">
        <atoms-button class="teal white--text" style="width: 100%" @click="exportXlsx">
          <v-icon left>mdi-google-spreadsheet</v-icon>
          Ekspor XLSX
        </atoms-button>
      </v-col>
      <v-col cols="12" lg="3">
        <atoms-button class="brown white--text" style="width: 100%" @click="showAdminSetting = { ...rest, worker }">
          <v-icon left>mdi-percent-circle</v-icon>
          Edit Persentase Fee (Jika EWA)
        </atoms-button>
      </v-col>
      <v-col cols="12" lg="auto" class="d-flex justify-center justify-lg-end align-center grow py-0 my-0 ml-5">
        <v-checkbox v-model="enableDate" hide-spin-buttons /><atoms-text>Filter dengan tanggal ?</atoms-text>
        <atoms-button @click="showTutorial = true" icon class="ml-5">
          <v-icon>mdi-information</v-icon>
        </atoms-button>
      </v-col>
      <v-scroll-y-transition>
        <v-col cols="12" v-if="enableDate" class="my-0">
          <v-row dense>
            <v-col cols="12" md="6">
              <atoms-date-picker
                label="Dari Tanggal"
                :modal="from.modal"
                :value="from.value"
                @change="
                  (modal, value) => {
                    from.modal = modal
                    from.value = value
                  }
                "
              />
            </v-col>
            <v-col cols="12" md="6">
              <atoms-date-picker
                label="Sampai Tanggal"
                :modal="to.modal"
                :value="to.value"
                @change="
                  (modal, value) => {
                    to.modal = modal
                    to.value = value
                  }
                "
              />
            </v-col>
          </v-row>
        </v-col>
      </v-scroll-y-transition>
    </v-row>

    <div class="mt-4">
      <div v-if="mainLoading">
        <v-skeleton-loader
          v-for="i in 5"
          :key="i"
          type="list-item-avatar"
          class="accent rounded-lg mb-2 pa-2"
          :style="{
            'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
          }"
        />
      </div>
      <div v-else>
        <div v-if="$store.state.requestPayments.data && $store.state.requestPayments.data.length > 0">
          <!-- <atoms-text class="pa-2"
              ><v-icon>mdi-magnify</v-icon>Hasil Pencarian, {{ items.count }} data
              ditemukan</atoms-text
            > -->
          <!-- {{ checkboxItems }} -->

          <div
            v-for="({ worker, ...rest }, i) in $store.state.requestPayments.data || []"
            :key="i"
            :class="['accent', 'rounded-lg pa-4 mb-2']"
            :style="{
              'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
            }"
          >
            <v-row align="center">
              <v-col class="shrink">
                <v-checkbox :value="checkAll ? true : undefined" @click="addItems({ ...rest, worker })" />
              </v-col>
              <td class="text-start">
                <v-badge
                  :value="rest.totalComplainMessages || 0"
                  :color="true ? 'primary' : 'red darken-1'"
                  :content="rest.totalComplainMessages || 0"
                >
                  <v-icon
                    @click="
                      () => {
                        showComplain = { worker, ...rest }
                      }
                    "
                    >mdi-forum</v-icon
                  >
                </v-badge>
              </td>
              <v-col class="shrink">
                <v-avatar color="grey lighten-4 rounded-lg ml-4">
                  <atoms-image :enableZoom="true" v-if="worker && worker.photo" :src="worker.photo" />
                  <v-icon light v-else>mdi-account</v-icon></v-avatar
                >
              </v-col>
              <v-col cols="8" lg="4">
                <atoms-text :auto="true" class="font-weight-bold">{{
                  $toCapitalize((worker && worker.fullName) || 'Nama tidak dicantumkan')
                }}</atoms-text>
                <atoms-text class="primary--text">{{
                  (worker && worker.email) || 'pekerja.email@website.com'
                }}</atoms-text>
              </v-col>
              <v-col cols="12" lg="4" align="start">
                <atoms-text class="font-weight-bold">Saldo terakhir update : </atoms-text>
                <atoms-text class="primary--text">
                  {{ $moment(worker && worker._updatedDate).format('dddd, DD MMMM YYYY') }}
                </atoms-text>
              </v-col>
              <v-col align="center" class="shrink">
                <v-chip v-if="rest.type == $requestPaymentType.EWA" small class="rounded-lg mr-2 mb-2">EWA</v-chip>
                <v-chip v-if="worker && worker.captain" small class="rounded-lg mr-2 mb-2" color="primary white--text"
                  >Kapten</v-chip
                >
                <v-chip
                  v-if="rest._expiredDate && $moment.utc().isAfter(rest._expiredDate)"
                  small
                  class="rounded-lg mr-2 mb-2"
                  color="red white--text"
                  >Expired</v-chip
                >
                <v-chip
                  small
                  :class="['rounded-lg white--text mb-2', (rest && rest.approvedBy && 'teal ') || 'orange']"
                  >{{ (rest && rest.approvedBy && 'Approved') || 'Permintaan' }}</v-chip
                >
              </v-col>

              <v-col align="end" class="">
                <v-menu offset-x class="rounded-xl">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn depressed icon v-bind="attrs" v-on="on"><v-icon>mdi-dots-vertical</v-icon></v-btn>
                  </template>
                  <v-list nav dense elevation="0" class="text-center rounded-lg">
                    <v-list-item
                      link
                      :disabled="!worker"
                      @click="approveRequest({ worker, ...rest })"
                      class="rounded-lg px-4"
                    >
                      <v-icon left>mdi-check</v-icon>
                      <v-list-item-title class="px-2">Approve </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      link
                      :disabled="!worker"
                      @click="showHistory = { ...worker, balance: rest.balance }"
                      class="rounded-lg px-4"
                    >
                      <v-icon left>mdi-clock-outline</v-icon>
                      <v-list-item-title class="px-2"
                        >Riwayat Saldo {{ $idCurrency(rest.balance || 0) }}</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
            <v-container class="px-0 pb-0">
              <atoms-text
                ><v-icon left small>mdi-cash-multiple</v-icon> Permintaan :
                {{ $idCurrency(rest.nominal) || '0' }}</atoms-text
              >
              <atoms-text
                ><v-icon left small>mdi-history</v-icon> Expiry date :
                {{
                  (rest._expiredDate && $moment(rest._expiredDate).locale('en').format('DD/MM/YYYY - H:mm:ss A')) || '-'
                }}</atoms-text
              >
              <atoms-text v-if="rest.updatedBy"
                ><v-icon left small>mdi-account-sync</v-icon> Diperbarui oleh
                <span class="primary--text"
                  >{{ rest.updatedBy.nickname || '-' }} ({{ rest.updatedBy.email || '' }})</span
                ></atoms-text
              >

              <atoms-text v-if="rest.approvedBy"
                ><v-icon left small>mdi-account-sync</v-icon> Disetujui oleh
                <span class="primary--text"
                  >{{ rest.approvedBy.nickname || '-' }} ({{ rest.approvedBy.email || '' }})</span
                ></atoms-text
              >
            </v-container>
          </div>
          <v-row justify="center">
            <v-col cols="8">
              <v-container class="max-width">
                <v-pagination
                  class="my-4"
                  v-model="page"
                  :length="$store.state.requestPayments.pagesLength"
                ></v-pagination>
              </v-container>
            </v-col>
          </v-row>
        </div>
        <molecules-wrapper v-else class="d-flex justify-center align-center my-8 py-8 outlined">
          <div class="text-center">
            <atoms-image :width="250" src="/assets/missing.svg" />
            <atoms-title :h3="true" class="mt-6">Tidak ada pekerja yang ditemukan!</atoms-title>
          </div>
        </molecules-wrapper>
      </div>
    </div>

    <!-- history -->
    <v-slide-x-reverse-transition>
      <organism-workers-history-balance
        v-if="showHistory"
        :data="showHistory"
        :pureEdit="true"
        @closed="
          () => {
            showHistory = null
          }
        "
      />
    </v-slide-x-reverse-transition>
    <v-slide-x-reverse-transition>
      <organism-request-payment-complain
        v-if="showComplain"
        :id="showComplain && showComplain._id"
        :request="showComplain"
        @closed="
          () => {
            loadData()
            showComplain = null
            this.$vuetify.goTo(0)
          }
        "
      />
    </v-slide-x-reverse-transition>
    <v-slide-x-reverse-transition>
      <organism-request-payment-tutorial
        v-if="showTutorial"
        @closed="
          () => {
            showTutorial = false
            this.$vuetify.goTo(0)
          }
        "
      />
    </v-slide-x-reverse-transition>
    <v-slide-x-reverse-transition>
      <organism-request-payment-percentage
        v-if="showAdminSetting"
        :data="showAdminSetting"
        @close="
          () => {
            showAdminSetting = null
            this.$vuetify.goTo(0)
          }
        "
      />
    </v-slide-x-reverse-transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      term: '',
      page: 1,
      limit: 10,
      sort: '',
      checkboxItems: [],
      checkAll: false,
      enableDate: false,

      from: {
        modal: false,
        value: this.$moment().startOf('month').format('YYYY-MM-DD'),
      },
      to: {
        modal: false,
        value: this.$moment().format('YYYY-MM-DD'),
      },
      showComplain: null,
      showTutorial: false,
      showAdminSetting: null,
      showHistory: null,
      constant: ['Desc', 'Asc'],
      reportLoading: false,
      mainLoading: false,
      filter: null,
      filters: [
        {
          title: 'Semua',
          value: {
            approved: undefined,
          },
        },
        {
          title: 'Permintaan',
          value: {
            approved: { $exists: false },
          },
        },
        {
          title: this.$status.approve,
          value: {
            approved: { $eq: true },
          },
        },
        {
          title: this.$status.reject,
          value: {
            approved: { $eq: false },
          },
        },
        {
          title: this.$requestPaymentType.EWA,
          value: {
            type: this.$requestPaymentType.EWA,
          },
        },
        {
          title: this.$requestPaymentType.Regular,
          value: {
            type: this.$requestPaymentType.Regular,
          },
        },
        {
          title: 'Rekening BCA',
          value: {
            'worker.accountDetails.bankName': {
              $regex: this.$accountName.BCA,
              $options: 'i',
            },
          },
        },
        {
          title: 'Rekening OVO',
          value: {
            'worker.accountDetails.bankName': {
              $regex: this.$accountName.OVO,
              $options: 'i',
            },
          },
        },
        {
          title: 'Rekening GOPAY',
          value: {
            'worker.accountDetails.bankName': {
              $regex: this.$accountName.GoPay,
              $options: 'i',
            },
          },
        },
      ],
      exportData: {
        email: 'arsip.hayokerja@gmail.com',
      },
    }
  },
  watch: {
    checkAll: {
      handler(value) {
        if (!!value) {
          this.checkboxItems = this.$store.state.requestPayments.data
        } else {
          this.checkboxItems = []
        }
      },
      deep: true,
    },
    page: {
      handler() {
        this.getRequest()
      },
      deep: true,
    },
  },
  async mounted() {
    this.sort = this.constant[0]
    this.loadData()
  },
  computed: {
    filterQuery: function () {
      const term = { $regex: this.term || '', $options: 'i' }
      return {
        pipeline: [
          {
            $sort: {
              _createdDate: this.sort === this.constant[0] ? -1 : 1,
            },
          },
          ...this.$store.state.requestPayments.defaultPipeline,
          {
            $match: {
              $or: [
                {
                  'worker.idNumber': term,
                },
                {
                  'worker.fullName': term,
                },
                {
                  'worker.email': term,
                },
                {
                  'worker.phone': term,
                },
              ],
              ...(this.filter && this.filter.length > 0
                ? Object.assign({}, ...this.filter?.map((x) => this.filters?.find((y) => y.title === x)?.value))
                : []),
              _updatedDate: this.enableDate
                ? {
                    $gte: new Date(new Date(this.from.value).setHours(0, 0, 0, 0)),
                    $lt: new Date(new Date(this.to.value).setHours(45, 0, 0, 0)),
                  }
                : { $exists: true },
            },
          },
        ],
      }
    },
  },
  methods: {
    async loadData() {
      this.page = 1
      await this.getRequest()
    },
    async addItems(item) {
      if (!this.checkboxItems?.find((x) => x._id == item._id)) {
        this.checkboxItems.push(item)
      } else {
        this.checkboxItems = this.checkboxItems?.filter((x) => x._id != item._id)
      }
      if (this.checkboxItems?.length >= this.$store.state.requestPayments.data?.length) {
        this.checkAll = true
      } else if (this.checkAll) {
        const temp = [...this.checkboxItems]
        this.checkAll = false
        this.checkboxItems = temp
      }
    },
    async approveBulkRequest(payload) {
      this.mainLoading = true
      try {
        if (Array.isArray(payload)) {
          payload = payload?.filter((it) => !it?.approved)
        }
        if (Array.isArray(payload) && payload?.length > 0) {
          await this.$store.dispatch('requestPayments/bulkApprove', {
            _ids: payload?.map((it) => it?._id),
          })
        } else {
          alert('Tidak ada permintaan dari pekerja yang Anda list')
        }
        this.checkAll = false
        this.checkboxItems = []
      } catch (error) {
        throw new Error(`Salah Satu Permintaan Gagal : ${error.message || error}`)
      } finally {
        await this.loadData()
        this.mainLoading = false
      }
    },
    async approveRequest(payload, approve = true) {
      this.mainLoading = true
      // let errors = []
      try {
        // if (Array.isArray(payload)) {
        //   payload = payload?.filter((it) => !it?.approved)
        // }
        if (
          payload?._id &&
          !payload?.approved &&
          confirm(
            `Apakah Anda yakin ingin menerima permintaan payment dari ${
              payload?.worker?.fullName || payload?.worker?.email || '-'
            }`,
          )
        ) {
          delete payload?.worker
          // await Promise.all(
          //   payload?.map(async (it) => {
          //     delete it?.balance
          //     return
          await this.$store.dispatch('requestPayments/approve', payload)
          //   })
          // ).then((result) => {
          //   console.log(result)
          // })
          this.checkAll = false
          this.checkboxItems = []
          this.loadData()
        } else if (payload?.approvedBy) {
          alert(
            `Permintaan tersebut sudah disetujui sebelumnya ${
              payload?.approvedBy ? `oleh ${payload?.approvedBy?.name || payload?.approvedBy?.email}` : '.'
            }`,
          )
        }
        // else {
        //   this.$showDialog({
        //     title: `Permintaan Gagal`,
        //     body: "",
        //   })
        // }
        // if (errors?.length > 0) {
        //   this.$showDialog({
        //     title: `Beberapa pekerja gagal menerima permintaan karena ${
        //       errors?.[0]?.error || "-"
        //     }`,
        //     body: `Daftar pekerja yang dimaksud ${errors
        //       ?.map((it) => it?.fullName || it?.email)
        //       ?.join()}`,
        //   })
        // }
      } catch (error) {
        throw new Error(error)
      } finally {
        this.mainLoading = false
      }
    },
    async getRequest() {
      this.mainLoading = true
      try {
        await this.$store.dispatch('requestPayments/getAll', {
          custom: this.filterQuery,
          page: this.page,
          limit: this.limit,
        })
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.mainLoading = false
      }
    },
    async exportXlsx() {
      const isConfirmed = confirm('Sudah yakin untuk ekspor datanya?')
      if (!isConfirmed) return
      this.$store.commit('SET', { loading: true })
      try {
        const filter = this.filterQuery
        const realm = await this.$realm()
        const items = await realm.functions.getRequestPayments(JSON.stringify(filter))
        const columns = [
          {
            label: '_id',
            value: '_id',
          },
          {
            label: 'Transfer Type',
            value: 'Transfer Type',
          },
          {
            label: 'Credited Account',
            value: 'Credited Account',
          },
          {
            label: 'Amount',
            value: 'Amount',
          },
          {
            label: 'Remark 1',
            value: 'Remark 1',
          },
          {
            label: 'Remark 2',
            value: 'Remark 2',
          },
          {
            label: 'Rcv Name',
            value: 'Rcv Name',
          },
          {
            label: 'Email',
            value: 'Email',
          },
          {
            label: 'ㅤ',
            value: 'ㅤ',
          },
        ]
        const content = items.map((item) => {
          const accountNumber = item.worker?.accountDetails?.accountNumber || ''
          return {
            _id: item._id,
            'Transfer Type': item.worker?.accountDetails?.bankName,
            'Credited Account': accountNumber,
            Amount: item.nominal,
            'Remark 1': ' ',
            'Remark 2': item.worker?.accountDetails?.accountName,
            'Rcv Name': item.worker?.fullName,
            Email: this?.exportData?.email || 'arsip.hayokerja@gmail.com',
            ㅤ: accountNumber.length,
          }
        })
        let data = [
          {
            sheet: 'Result',
            columns,
            content,
          },
        ]
        let settings = {
          fileName: `Request Payments ${this.$moment().format('DD-MM-YYYY')}.xlsx`,
        }
        this.$xlsx(data, settings)
      } catch (err) {
        if (err?.message === 'Request failed with status code 500') {
          this.$store.commit('SET', { loading: false })
          return this.$showDialog({
            title: 'Maaf',
            body: 'Penarikan data gagal',
          })
        }
        this.$showDialog({
          title: 'Terdapat Kendala',
          body: err?.response?.data?.message || err?.message,
        })
      }
      this.$store.commit('SET', { loading: false })
    },
    // async resetAllBalances() {
    //   const isConfirmed = confirm(
    //     "Sudah yakin untuk reset semua saldo pekerja? Pastikan anda sudah export xlsx sebelum mengeksekusi"
    //   )
    //   if (!isConfirmed) return
    //   const validateBalance = prompt(
    //     `Masukkan total saldo ( ${this.$store.state.worker.total} ), untuk melanjutkan reset saldo`
    //   )
    //   if (!validateBalance) return
    //   if (+validateBalance !== +this.$store.state.worker.total)
    //     return alert("Inputan total saldo salah")
    //   this.$store.commit("SET", { loading: true })
    //   try {
    //     await this.$api.post(`WorkerBalances/reset`, {
    //       validateBalance,
    //     })
    //     await this.getRequest()
    //   } catch (err) {
    //     this.$showDialog({
    //       title: "Terdapat Kendala",
    //       body: err?.response?.data?.message || err?.message,
    //     })
    //   }
    //   this.$store.commit("SET", { loading: false })
    // },
  },
}
</script>
